<template>
  <div>
    <h2>Fireplace Room</h2>
    <p class="temperature">
      <strong>{{latestTemperature}} F</strong> {{latestTemperatureTime}}
    </p>
    <p class="humidity">
      <strong>{{latestHumidity}}% humidity</strong> {{latestHumidityTime}}
    </p>
    <p>
      <button v-for="range in ranges" @click="e => buttonRange_onClickHandler(range)" :style="buttonStyle(range)">{{range.name}}</button>
      <button @click="buttonRefresh_onCLickHandler">Reload</button>
    </p>
    <div v-if="!loading" style="border-top: 2px solid black;">
      <p>{{curRange.startDate().toLocaleString()}} - {{curRange.endDate().toLocaleString()}}</p>
      <h4>Temperature (F)</h4>
      <p class="temperature">
        <strong>{{averageTemperature}} F average</strong>
      </p>
      <div id="temperatureGraph" style="width: calc(100vw - 40px);" />
      <h4>Humidity (%)</h4>
      <p class="humidity">
        <strong>{{averageHumidity}} % average</strong>
      </p>
      <div id="humidityGraph" style="width: calc(100vw - 40px);" />
    </div>
    <div v-else>
      Loading...
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { Graph2d, DataSet } from "vis-timeline/dist/vis-timeline-graph2d.esm";
import "vis-timeline/dist/vis-timeline-graph2d.min.css";

async function fetchData(apiDomain, range) {
  let deviceEvents = [];
  let url = `${apiDomain}/device-events/timeline?locationKey=fireplace_room&endTs=${range.endDate().getTime()}&startTs=${range.startDate().getTime()}&types=humidity,temperature`;

  await fetch(url).then(result => result.json()).then(body => {
    deviceEvents = body.deviceEvents;
    deviceEvents.forEach (di => di.received_at = new Date(di.received_at));

    deviceEvents.sort((a, b) => {
      return a.received_at.getTime() > b.received_at.getTime() ? 1 : -1;
    });
  });

  return deviceEvents;
};

const ranges = [{
  name: '1h',
  startDate: () => {
    const ago = new Date();
    ago.setMinutes(ago.getMinutes() - 60);
    return ago;
  },
  endDate: () => {
    return new Date();
  },
  timeAxis: { scale: 'minute', step: 5 }
},{
  name: '2h',
  startDate: () => {
    const ago = new Date();
    ago.setMinutes(ago.getMinutes() - 120);
    return ago;
  },
  endDate: () => {
    return new Date();
  },
  timeAxis: { scale: 'minute', step: 15 }
},{
  name: '4h',
  startDate: () => {
    const ago = new Date();
    ago.setMinutes(ago.getMinutes() - 240);
    return ago;
  },
  endDate: () => {
    return new Date();
  },
  timeAxis: { scale: 'minute', step: 30 }
},{
  name: '24h',
  startDate: () => {
    const ago = new Date();
    ago.setMinutes(ago.getMinutes() - (60 * 24));
    return ago;
  },
  endDate: () => {
    return new Date();
  },
  timeAxis: { scale: 'hour', step: 2 }
},{
  name: 'Today',
  startDate: () => {
    const ago = new Date();
    ago.setHours(0);
    ago.setMinutes(0);
    return ago;
  },
  endDate: () => {
    const ago = new Date();
    ago.setHours(23);
    ago.setMinutes(59);
    return ago;
  },
  timeAxis: { scale: 'hour', step: 2 }
},{
  name: 'Yesterday',
  startDate: () => {
    const ago = new Date();
    ago.setDate(ago.getDate() - 1);
    ago.setHours(0);
    ago.setMinutes(0);
    return ago;
  },
  endDate: () => {
    const ago = new Date();
    ago.setDate(ago.getDate() - 1);
    ago.setHours(23);
    ago.setMinutes(59);
    return ago;
  },
  timeAxis: { scale: 'hour', step: 2 }
}];

const DEFAULT = ranges[0];

export default {
  data() {
    return {
      deviceEvents: [],
      loading: false,
      ranges,
      curRange: DEFAULT,
      averageTemperature: -1,
      averageHumidity: -1
    }
  },
  async asyncData ({ $config }) {
    let deviceEvents = await fetchData($config.apiDomain, DEFAULT);

    return {
      deviceEvents
    };
  },
  mounted() {
    this.renderChart();
  },
  methods: {
    async reload() {
      this.humidityGraph2d.destroy();
      this.temperatureGraph2d.destroy();
      this.humidityGraph2d = this.temperatureGraph2d = undefined;
      this.loading = true;
      this.deviceEvents = await fetchData(this.$config.apiDomain, this.curRange);
      this.loading = false;
      setTimeout(() => {
        this.renderChart();
      }, 50);
    },
    buttonStyle(range) {
      return range === this.curRange ? 'font-size: 16px; font-weight: bold; margin-right: 10px; padding: 10px;' : 'margin-right: 10px;padding: 10px;';
    },
    async buttonRange_onClickHandler(range) {
      this.curRange = range;

      this.reload();
    },
    renderChart() {
      this.renderTemperatureChart();
      this.renderHumidityChart();
    },
    renderTemperatureChart() {
      this.temperatureItems = this.deviceEvents.filter(e => e.type === 'temperature').map(event => {
        return {
          id: event.id,
          x: new Date(event.received_at),
          y: event.data.temperature * 9 / 5 + 32
        }
      });
      let count = this.temperatureItems.length;
      let last = -1;
      let total = 0;
      this.temperatureItems = this.temperatureItems.filter((ti, ix) => {
        const sameAsLast = ti.y === last;
        last = ti.y;
        total += ti.y;
        return !sameAsLast || ix === 0 || ix === this.temperatureItems.length - 1;
      });

      this.averageTemperature = total / count;

      if (this.temperatureGraph2d) {
        this.temperatureGraph2d.destroy();
        this.temperatureGraph2d = undefined;
      }

      this.container = document.getElementById("temperatureGraph");

      this.temperatureDataSet = new DataSet({});
      this.temperatureDataSet.add(this.temperatureItems);
      let options = {
        sort: false,
        start: this.curRange.startDate(),
        end: this.curRange.endDate(),
        timeAxis: this.curRange.timeAxis,
        interpolation: false
      };

      this.temperatureGraph2d = new Graph2d(this.container, this.temperatureDataSet, options);
    },
    renderHumidityChart() {
      this.humidityItems = this.deviceEvents.filter(e => e.type === 'humidity').map(event => {
        return {
          id: event.id,
          x: event.received_at,
          y: event.data.humidity
        }
      });

      let count = this.humidityItems.length;
      let last = -1;
      let total = 0;
      this.humidityItems = this.humidityItems.filter((ti, ix) => {
        const sameAsLast = ti.y === last;
        last = ti.y;
        total += ti.y;
        return !sameAsLast || ix === 0 || ix === this.humidityItems.length - 1;
      });

      this.averageHumidity = total / count;

      if (this.humidityGraph2d) {
        this.humidityGraph2d.destroy();
        this.humidityGraph2d = undefined;
      }

      this.container = document.getElementById("humidityGraph");

      this.humidityDataSet = new DataSet({});
      this.humidityDataSet.add(this.humidityItems);
      let options = {
        sort: false,
        start: this.curRange.startDate(),
        end: this.curRange.endDate(),
        timeAxis: this.curRange.timeAxis,
        interpolation: false
      };

      this.humidityGraph2d = new Graph2d(this.container, this.humidityDataSet, options);
    },
    latestFor(type) {
      for (let i = this.deviceEvents.length - 1; i >= 0; i--) {
        const de = this.deviceEvents[i];

        if (de.type === type) {
          return de;
        }
      }

      return undefined;
    },
    async buttonRefresh_onCLickHandler() {
      this.reload();
    }
  },
  computed: {
    startDate() {
      const ago = new Date();
      ago.setMinutes(ago.getMinutes() - 60);
      return ago;
    },
    endDate() {
      return new Date();
    },
    latestTemperature() {
      return this.latestFor('temperature') ? this.latestFor('temperature').data.temperature * 9 / 5 + 32 : 'N/A';
    },
    latestTemperatureTime() {
      return this.latestFor('temperature') ? moment(new Date(this.latestFor('temperature').received_at)).fromNow(): 'N/A';
    },
    latestHumidity() {
      return this.latestFor('humidity') ? this.latestFor('humidity').data.humidity : 'N/A';
    },
    latestHumidityTime() {
      return this.latestFor('humidity') ? moment(new Date(this.latestFor('humidity').received_at)).fromNow() : 'N/A';
    },
  },
  head () {
    return {
      title:'Dashboard | Junghaus',
      meta: [
        { hid: 'description', name: 'description', content: 'Temperature and more.' }
      ]
    }
  }
}
</script>
<style>
body {
  padding: 10px;
}
</style>
